import React, { createContext, useContext, useState, useRef, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { MediaSharedClassNames } from '@atlaskit/editor-common/styles';

import { getNextMarkerRef } from '@confluence/comments-util/entry-points/domUtils';
import {
	checkElementExpandInEditor,
	scrollToComment,
	calculateElementOffset,
} from '@confluence/comments-util';

import type { CommentHighlight } from './commentContextTypes';

export type CreateInlineCommentHighlight = CommentHighlight;

export type HighlightOrigin = 'userHighlight' | 'nudgeHighlight';

type NavigateToCommentProps = {
	action: 'next' | 'previous';
	triggeredByKeyboardShortcut: boolean;
	currentCommentIndex: number;
	onNavigationClick: (nextMarkerRef: string) => void;
	isEditor?: boolean;
	removeCommentQueryParams: () => void;
	unresolvedInlineComments: string[];
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	contentId?: string;
};

export type ResolvedComment = {
	commentId: string;
	inlineMarkerRef: string;
};

type InlineCommentsContextValue = {
	newCommentHighlight?: CreateInlineCommentHighlight;
	highlightOrigin: HighlightOrigin;
	activeHighlight: string | null;
	firstHighlightClicked: boolean;
	shouldDisplaySidebar: boolean;
	resolvedComment: ResolvedComment | null;
};

type InlineCommentsDispatchContextValue = {
	createComment: (newHighlight: CreateInlineCommentHighlight, origin?: HighlightOrigin) => void;
	clearCreateHighlight: () => void;
	onHighlightClick: (markerRef: string | null) => void;
	toggleSidebar: (shouldDisplay: boolean) => void;
	setResolvedComment: React.Dispatch<React.SetStateAction<ResolvedComment | null>>;
	setActiveHighlight: React.Dispatch<React.SetStateAction<string | null>>;
	navigateToComment: ({
		action,
		triggeredByKeyboardShortcut,
		currentCommentIndex,
		onNavigationClick,
		isEditor,
		removeCommentQueryParams,
		unresolvedInlineComments,
		createAnalyticsEvent,
		contentId,
	}: NavigateToCommentProps) => void;
};

export const InlineCommentsDispatchContext = createContext<InlineCommentsDispatchContextValue>({
	createComment: () => {},
	clearCreateHighlight: () => {},
	onHighlightClick: () => {},
	toggleSidebar: () => {},
	setResolvedComment: () => {},
	setActiveHighlight: () => {},
	navigateToComment: () => {},
});
InlineCommentsDispatchContext.displayName = 'InlineCommentsDispatchContext';

export const InlineCommentsContext = createContext<InlineCommentsContextValue>({
	newCommentHighlight: undefined,
	highlightOrigin: 'userHighlight',
	activeHighlight: null,
	firstHighlightClicked: false,
	shouldDisplaySidebar: false,
	resolvedComment: null,
});
InlineCommentsContext.displayName = 'InlineCommentsContext';

type InlineCommentsProviderProps = {
	children: ReactNode;
};

export const InlineCommentsProvider: FC<InlineCommentsProviderProps> = ({ children }) => {
	const [newCommentHighlight, setNewCommentHighlight] = useState<CreateInlineCommentHighlight>();

	const [highlightOrigin, setHighlightOrigin] = useState<HighlightOrigin>('userHighlight');

	/**
	 * We eventually want this value to be the official state for loading/displaying
	 * the UI for inline comments. InlineCommentSidebar and some child components have created
	 * stateful logic (active highlight, isSidebarShown, etc) that assumes
	 * the code is always loaded which we will eventually adjust.
	 */
	const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState<boolean>(false);

	const firstHighlightClicked = useRef(false);

	const [activeHighlight, setActiveHighlight] = useState<string | null>(null);

	const [resolvedComment, setResolvedComment] = useState<ResolvedComment | null>(null);

	const providerValue = useMemo(
		() => ({
			newCommentHighlight,
			highlightOrigin,
			activeHighlight,
			resolvedComment,
			firstHighlightClicked: firstHighlightClicked.current,
			shouldDisplaySidebar,
		}),
		[
			activeHighlight,
			highlightOrigin,
			newCommentHighlight,
			resolvedComment,
			shouldDisplaySidebar,
			firstHighlightClicked,
		],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			createComment: (
				newHighlight: CreateInlineCommentHighlight,
				origin: HighlightOrigin = 'userHighlight',
			) => {
				/**
				 * Creating a comment is one of the three user explicit scenarios
				 * where we want to load inline comments code.
				 */
				setShouldDisplaySidebar(true);
				setNewCommentHighlight(newHighlight);
				setHighlightOrigin(origin);
				if (resolvedComment) {
					setResolvedComment(null);
				}
			},
			clearCreateHighlight: () => setNewCommentHighlight(undefined),
			onHighlightClick: (markerRef: string | null) => {
				if (!firstHighlightClicked.current) {
					firstHighlightClicked.current = true;
				}

				/**
				 * Clicking on a highlight is one of the three user explicit scenarios
				 * where we want to load inline comments code.
				 */
				if (markerRef) {
					setShouldDisplaySidebar(true);
				} else {
					setShouldDisplaySidebar(false);
				}

				setActiveHighlight(markerRef);
			},
			setResolvedComment,
			toggleSidebar: (shouldDisplay: boolean) => setShouldDisplaySidebar(shouldDisplay),
			setActiveHighlight,
			navigateToComment: ({
				action,
				triggeredByKeyboardShortcut,
				currentCommentIndex,
				onNavigationClick,
				isEditor,
				removeCommentQueryParams,
				unresolvedInlineComments,
				createAnalyticsEvent,
				contentId,
			}: NavigateToCommentProps) => {
				if (unresolvedInlineComments?.length < 2) {
					return;
				}

				// Clear the focusedCommentId queryParams so that reply does not opens up if any...
				removeCommentQueryParams();

				const nextMarkerRef = getNextMarkerRef(
					action,
					currentCommentIndex,
					unresolvedInlineComments,
				);

				const currentCommentId = unresolvedInlineComments[currentCommentIndex];

				if (!nextMarkerRef) {
					return;
				}

				const nextCommentElement = document.getElementById(nextMarkerRef);
				const previousCommentElement = document.getElementById(currentCommentId);

				if (nextCommentElement) {
					checkElementExpandInEditor(nextCommentElement);
				}

				onNavigationClick(nextMarkerRef);

				const previousOffset = previousCommentElement
					? calculateElementOffset(previousCommentElement)
					: 0;

				const offset = calculateElementOffset(nextCommentElement);

				const scrollableContainerSelector = isEditor
					? '.fabric-editor-popup-scroll-parent'
					: '#content-body';

				const scrollableContainer = document.querySelector(scrollableContainerSelector);

				const differenceOffset = offset - previousOffset;
				scrollableContainer?.scrollBy(0, differenceOffset);

				scrollToComment(nextCommentElement, previousCommentElement, true, Boolean(isEditor));

				if (nextCommentElement) {
					// As we navigate inline comments with arrow icons, we want to update the cursor location to where the open inline comment is
					// unless it is a media node in which case, we want our selection to be the media node element.
					const media = nextCommentElement.querySelector(
						`.${MediaSharedClassNames.MEDIA_CONTAINER}`,
					);
					if (media) {
						window.getSelection()?.setPosition(media);
					} else {
						window.getSelection()?.setPosition(nextCommentElement);
					}
				}

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'navigate',
						actionSubjectId: 'inlineCommentArrowsNavigate',
						attributes: {
							triggeredByKeyboardShortcut,
							action,
						},
						objectType: 'inlineComment',
						objectId: contentId,
						source: isEditor ? 'editPageScreen' : 'renderPageScreen',
					},
				}).fire();
			},
		}),
		[
			resolvedComment,
			firstHighlightClicked,
			setShouldDisplaySidebar,
			setHighlightOrigin,
			setResolvedComment,
			setActiveHighlight,
		],
	);

	return (
		<InlineCommentsContext.Provider value={providerValue}>
			<InlineCommentsDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</InlineCommentsDispatchContext.Provider>
		</InlineCommentsContext.Provider>
	);
};

export const useInlineCommentsContext = () => {
	return useContext(InlineCommentsContext);
};

export const useInlineCommentsDispatchContext = () => {
	return useContext(InlineCommentsDispatchContext);
};
